import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.VUE_APP_WEBSITE_PATH || '/',
    routes: [
        {
            path: '/',
            name: 'front',
            component: () => import(/* webpackChunkName: "front" */ './views/Front.vue'),
        },
        {
            path: '/payment/account',
            name: 'payment-account',
            component: () => import(/* webpackChunkName: "payment-account" */ './views/payment/Account.vue'),
        },
        {
            path: '/interaction',
            name: 'interaction',
            component: () => import(/* webpackChunkName: "interaction" */ './views/Interaction.vue'),
        },
        {
            path: '/login',
            name: 'login',
            component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
            path: '/preferences',
            name: 'preferences',
            component: () => import(/* webpackChunkName: "preferences" */ './views/Preferences.vue'),
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: () => import(/* webpackChunkName: "dashboard" */ './views/Dashboard.vue'),
        },
        {
            path: '/create-account',
            name: 'create-account',
            component: () => import(/* webpackChunkName: "create-account" */ './views/CreateAccount.vue'),
        },
        {
            path: '/create-account/conflict',
            name: 'create-account-conflict',
            component: () => import(/* webpackChunkName: "create-account-conflict" */ './views/CreateAccountConflict.vue'),
        },
        {
            path: '/create-account/login',
            name: 'create-account-login',
            component: () => import(/* webpackChunkName: "create-account-login" */ './views/CreateAccountLogin.vue'),
        },
        {
            path: '/delete-account',
            name: 'delete-account',
            component: () => import(/* webpackChunkName: "delete-account" */ './views/DeleteAccount.vue'),
        },
        {
            path: '/recover-access',
            name: 'recover-access',
            component: () => import(/* webpackChunkName: "recover-access" */ './views/RecoverAccess.vue'),
        },
        {
            path: '/vpn/reset-password',
            name: 'vpn-reset-password',
            component: () => import(/* webpackChunkName: "vpn-reset-password" */ './views/vpn/ResetPassword.vue'),
        },
//////////////////////////
/////////
//////////////////////////
/////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
//////////
/////////////////
    ],
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;
