import Vue from 'vue';
import Vuex from 'vuex';

import { Client } from '@/client';

const client = new Client();

Vue.use(Vuex);

/*
Do not redirect user from here (no $router.push). Respond to calling code
with appropriate indicators and let the calling code redirect as needed.
*/

export default new Vuex.Store({
    state: {
        isReady: false, // indicates that we loaded session info from server, so we know if user is authenticated; and if user is authenticated, that we've also loaded user info and account info from server
        serviceContext: {}, // stripeTokenPublicKey
        session: { isAuthenticated: false }, // isAuthenticated, notAfter, isCsrfGuardEnabled
        user: {}, // name, sessionIdleExpiresMillis
        userEmail: [],
        nav: { queue: [] }, // queue  with items pushed to it, so whenever we are done with something and want to know where to return to, we pop the last item from the queue and go there; and each item can have a function to determine if it's still valid (and the user should be directed there) or if it should be ignored (remove from the queue and proceed to next item)
        loadingMap: { init: true },
        // noteList: [],
    },
    getters: {
        isLoading(state) {
            return Object.values(state.loadingMap).reduce((acc, item) => acc || item, false);
        },
    },
    mutations: {
        ready(state) {
            console.log('vuex store: ready');
            state.isReady = true;
        },
        setServiceContext(state, contextInfo) {
            state.serviceContext = contextInfo;
        },
        setSession(state, session) {
            state.session = session;
        },
        setUser(state, user) {
            state.user = user;
        },
        setNav(state, nav) {
            state.nav = nav;
        },
        loading(state, progress) {
            state.loadingMap = { ...state.loadingMap, ...progress };
        },
        // noteList(state, values) {
        //     state.noteList = [...values];
        // },
    },
    actions: {
        async createAccount({ commit, dispatch, state }, accountInfo) {
            commit('loading', { createAccount: true });
            const response = await client.user.create(accountInfo);
            if (response.isCreated) {
                await dispatch('loadSession');
                if (state.session.isAuthenticated) {
                    await dispatch('loadAccount');
                }
            }
            commit('loading', { createAccount: false });
            return response;
        },
        async login({ commit, dispatch, state }, {
            interactionId, username, mode, verifyToken,
        }) {
            commit('loading', { loginWithLoginShield: true });
            // NOTE: username and mode are used to initiate a login; token is used to finish a login request
            const {
                isAuthenticated, next, error, forward, interactionId: nextInteractionId,
            } = await client.session.login({
                interactionId, username, mode, verifyToken,
            });
            // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
            if (verifyToken) {
                commit('setSession', { ...state.session, isAuthenticated });
                if (isAuthenticated) {
                    await dispatch('loadUser');
                } else {
                    commit('setUser', {});
                }
            }
            commit('loading', { loginWithLoginShield: false });
            return {
                isAuthenticated, next, error, forward, interactionId: nextInteractionId,
            };
        },
        async logout({ commit }) {
            commit('loading', { logout: true });
            await client.session.logout();
            // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
            commit('setSession', { isAuthenticated: false });
            commit('loading', { logout: false });
        },
        async enableCsrfGuard({ commit, state }) {
            const csrfTokenResponse = await client.session.createCsrfToken();
            if (csrfTokenResponse.token) {
                const csrfGuardToken = csrfTokenResponse.token;
                localStorage.setItem('csrfGuardToken', csrfGuardToken);
                commit('setSession', { ...state.session, isCsrfGuardEnabled: true, csrfGuardToken });
            }
        },
        async init({ commit, dispatch, state }, { force = false } = {}) {
            if (state.isReady && !force) {
                console.log('vuex store: init already done');
                return;
            }
            console.log('vuex store: init');
            commit('loading', { init: true });
            try {
                await Promise.all([
                    dispatch('loadServiceInfo'),
                    dispatch('loadSession'),
                ]);
                console.log('vuex store: loaded service info and session');
                /*
                if (state.session.isCsrfGuardEnabled && state.session.csrfGuardToken) {
                    // csrf guard enabled, store the token for use by loginshiedl client
                    localStorage.setItem('csrfGuardToken', state.session.csrfGuardToken);
                } else {
                    // enable csrf guard
                    await dispatch('enableCsrfGuard');
                }
                */
                if (state.session.isAuthenticated) {
                    // load data concurrently
                    await Promise.all([
                        dispatch('loadUser'),
                    ]);
                }
                console.log('vuex store: ready');
                commit('ready');
            } catch (err) {
                console.error('vuex store: init failed');
            }
            commit('loading', { init: false });
        },
        async refresh({ dispatch, state }) {
            console.log('vuex store: refresh');
            // not displaying loading bar because we want this to be transparent
            try {
                await dispatch('loadSession', { progressIndicator: false });
                if (state.session.isAuthenticated) {
                    await dispatch('loadUser', { progressIndicator: false });
                }
            } catch (err) {
                console.error('vuex store: refresh failed');
            }
        },
        async loadServiceInfo({ commit }) {
            commit('loading', { loadServiceInfo: true });
            try {
                const contextInfo = await client.service.getContext();
                commit('setServiceContext', contextInfo);
            } catch (err) {
                console.error('vuex store: failed to load service info');
            }
            commit('loading', { loadServiceInfo: false });
        },
        async loadSession({ commit, dispatch }, { progressIndicator = true } = {}) {
            if (progressIndicator) {
                commit('loading', { loadSession: true });
            }
            try {
                const sessionInfo = await client.session.get();
                commit('setSession', sessionInfo);
                const { isAuthenticated, notAfter } = sessionInfo;
                if (isAuthenticated && notAfter) {
                    const delay = notAfter - Date.now();
                    console.log(`vuex store: scheduling session reload for ${delay} ms`);
                    setTimeout(() => {
                        console.log('vuex store: reloading session');
                        dispatch('loadSession');
                    }, delay);
                }
            } catch (err) {
                commit('setSession', { fault: { type: 'read-failed' } });
            }
            commit('loading', { loadSession: false });
        },
        async loadUser({ commit }, { progressIndicator = true } = {}) {
            if (progressIndicator) {
                commit('loading', { loadUser: true });
            }
            try {
                const userInfo = await client.user.get();
                commit('setUser', userInfo);
            } catch (err) {
                commit('setUser', { fault: { type: 'read-failed' } });
            }
            commit('loading', { loadUser: false });
        },
        async editSession({ commit }, sessionInfo) {
            commit('loading', { editSession: true });
            let isEdited = false;
            try {
                const newSessionInfo = await client.session.edit(sessionInfo);
                commit('setSession', newSessionInfo);
                isEdited = true;
            } catch (err) {
                console.log('editSession error: %o', err);
            }
            commit('loading', { editSession: false });
            return isEdited;
        },
        async editUser({ commit, state }, userInfo) {
            commit('loading', { editUser: true });
            try {
                await client.user.edit(userInfo);
                // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
                const newUserInfo = { ...state.user, ...userInfo };
                commit('setUser', newUserInfo);
            } catch (err) {
                console.log('editUser error: %o', err);
            }
            commit('loading', { editUser: false });
        },
        queueNavItem({ commit, state }, item) {
            const queue = [...state.nav.queue];
            queue.push({ path: item.path, query: item.query || {}, hash: item.hash || '' });
            commit('setNav', { queue });
        },
        nextNavItem({ commit, state }) {
            try {
                const queue = [...state.nav.queue];
                if (queue.length > 0) {
                    const next = queue.splice(queue.length - 1, 1);
                    commit('setNav', { queue });
                    return next[0];
                }
                if (state.session.isAuthenticated) {
                    return { path: '/dashboard' };
                }
                return { path: '/' };
            } catch (err) {
                console.log('forward error: %o', err);
                return { path: '/' };
            }
        },
        // notes
        // async fetchNoteList({ state }) {
        //     const { list } = await client.note.list();
        //     // console.log(`list note: ${J} id ${id}`);
        //     state.noteList = list;
        // },

    },
});
