/*
Copyright (C) 2019 Cryptium Corporation. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

function withEndpoint(path) {
    const endpoint = process.env.VUE_APP_SERVICE_ENDPOINT_URL || '/service';
    console.log(`client.js: endpoint ${endpoint}`);
    let relativePath = path;
    if (path.startsWith('/')) {
        relativePath = path.substr(1);
    }
    const result = `${endpoint}/${relativePath}`;
    console.log(`client request to: ${result}`);
    return result;
}

async function getJson(path, query) {
    const response = await ajax.get(withEndpoint(path), {
        headers: {
            Accept: 'application/json',
        },
        params: query,
    });
    return response.data;
}

async function postJsonAcceptJson(path, request, query) {
    const response = await ajax.post(withEndpoint(path), JSON.stringify(request), {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        params: query,
    });
    return response.data;
}


class Service {
    async getContext() {
        return getJson('/context');
    }

    async getVersion() {
        return getJson('/version');
    }
}

class Session {
    async get() {
        return getJson('/session');
    }

    async login(request) {
        return postJsonAcceptJson('/session/login', request);
    }

    async logout(request = {}) {
        return postJsonAcceptJson('/session/logout', request);
    }
}


class User {
    async create(request) {
        return postJsonAcceptJson('/user/create', request);
    }

    async get() {
        return getJson('/user');
    }

    async edit(request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson('/user/edit', request);
    }

    async delete(request) {
        return postJsonAcceptJson('/user/delete', request);
    }

    async startRecoverAccess(request) {
        return postJsonAcceptJson('/user/recover-access/start', request);
    }

    async inputRecoverAccess(request) {
        return postJsonAcceptJson('/user/recover-access/input', request);
    }
}

class Account {
    async get() {
        return getJson('/user/account');
    }

    async getSubscriptionList() {
        return getJson('/account/subscription/list');
    }

    async getOrderList() {
        return getJson('/account/order/list');
    }
}


class Interaction {
    constructor(options, context) {
        // this.url = options.serviceEndpoint;
        this.context = context;
    }

    async create(request) {
        console.log('loginshield interaction create: request: %o', request);
        return postJsonAcceptJson('/interaction/create', request);
    }

    async get(id) {
        return getJson('/interaction', { id });
    }

    async edit(id, message) {
        return postJsonAcceptJson('/interaction/edit', message, { id });
    }

    async getTokenStatus(tokenId) {
        console.log('getTokenStatus');
        return getJson('/interaction-token', { id: tokenId });
    }

    async resumeWithToken(token) {
        console.log('resumeWithToken');
        return getJson('/interaction/resume', { token });
    }
}

/*
class Note {
    constructor(options, context) {
        this.options = options;
        this.context = context;
    }

    async create(request) {
        console.log('create note: request: %o', request);
        return postJsonAcceptJson('/note/create', request);
    }

    async edit(id, request) {
        console.log(`edit note ${id} request: ${JSON.stringify(request)}`);
        return postJsonAcceptJson('/note/edit', request, { id });
    }

    async fetch(request) {
        console.log('fetch note: request: %o', request);
        return getJson('/note/view', request);
    }

    async list(request) {
        console.log('list note: request: %o', request);
        return getJson('/note/list', request);
    }


    async delete(id) {
        console.log(`delete note ${id}`);
        return postJsonAcceptJson('/note/delete', null, { id });
    }
}
*/

class Vpn {
    constructor(options, context) {
        this.options = options;
        this.context = context;
    }

    async setPassword(request) {
        console.log('set vpn password: request: %o', request);
        return postJsonAcceptJson('/vpn/password', request);
    }
}


class Client {
    constructor() {
        this.interaction = new Interaction();
        // this.note = new Note();
        this.vpn = new Vpn();
        this.service = new Service();
        this.session = new Session();
        this.user = new User();
        this.account = new Account();
    }
}

export { Client };
